import styled, { css } from 'styled-components';

import Heading from '@app/components/atoms/Heading';
import Text from '@app/components/atoms/Text';
import FlexBox from '@app/components/atoms/FlexBox';

export const Container = styled(FlexBox)<{ isModal?: boolean }>`
  align-self: center;
  border-radius: 5px;
  padding: 50px 100px;
  margin-top: 100px;
  margin-bottom: ${({ isModal }) => (isModal ? `0` : '100px')};
  @media screen and (max-width: 600px) {
    padding: 50px 0px;
    width: 100%;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (max-width: 975px) {
    align-items: unset;
  }
`;

export const Title = styled(Heading)`
  margin: 0 0 8px 0;

  @media screen and (max-width: 975px) {
    margin: 60px 0 8px 0;
  }
`;

export const SignUpText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4f4f4f;
  cursor: pointer;
  margin-top: 10px;

  span {
    color: #2faf92;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 75%;
  max-width: 1440px;
  margin: 0 auto;

  @media screen and (max-width: 975px) {
    display: none;
  }
`;

export const NormalText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #969aa5;
  margin-top: 53px;
  align-self: baseline;
  margin-bottom: 9px;

  @media screen and (max-width: 975px) {
    margin-top: 44px;
    margin-bottom: 10px;
  }
`;

export const InputContainer = styled.div`
  width: 411px;

  @media screen and (max-width: 975px) {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  margin: 10px 0;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Label = styled(Text)`
  margin-bottom: 11px;
  position: relative;

  span {
    font-size: 16px;
    font-weight: 900;
    color: #2faf92;
    vertical-align: top;
    position: absolute;
    right: -20px;
    top: -8px;
  }
`;

export const LoginErrorContainer = styled.div`
  background: #fbc8ca;
  margin-top: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 22px 30px;
  min-width: 300px;

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0px;
    color: #c10c12;
  }

  a,
  span {
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: #4f4f4f;
    text-decoration: none;
  }
`;

export const ResendCode = styled.a`
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 11px;
  color: #2faf92;
  cursor: pointer;
`;

export const ErrorContainer = styled.div`
  min-height: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const ErrorText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: #ff7c33;
`;

export const LogoContainer = styled.div`
  margin-bottom: 15px;
`;
