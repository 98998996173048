/* eslint-disable @next/next/no-img-element */
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Auth } from 'aws-amplify';
import LogRocket from 'logrocket';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { destroyCookie, setCookie } from 'nookies';

import Head from '@app/components/molecules/Head';
import * as Styled from './styles';
import { useQuery } from '@app/hooks';
import { userUpdate, userReset } from '@app/store/user';
import { intakeReset } from '@app/store/intake';
import { homeownerIntakeReset } from '@app/store/homeownerIntake';
import { buyerIntakeReset } from '@app/store/buyerIntake';
import { progress } from '@app/utils/progressbar';
import { GET_USER } from '@app/queries/getUser';
import {
  Heading,
  Img,
  CodeInput,
  Text,
  FlexBox,
  Input,
  Button,
} from '@app/components/atoms';
import TemplateContainer from '@app/components/atoms/TemplateContainer';
import * as Sentry from '@sentry/nextjs';

type LogInProps = {
  isModal?: boolean;
  closeModal?: () => void;
  setModalScreen?: (value: string) => void;
};

const clearCookies = () => {
  const cookiesToClear = [
    'trustyRole',
    'buyerCriteriaId',
    'claimedHomeId',
    'transition',
    'intakePath',
  ];
  cookiesToClear.forEach((cookie) => destroyCookie({}, cookie, { path: '/' }));
};

const LogIn = ({ isModal, closeModal, setModalScreen }: LogInProps) => {
  const router = useRouter();
  const codeRef = useRef<any>(null);
  const cognitoUser = useRef(null);
  const theme = useTheme();

  const [error, setError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [immediate, setImmediate] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const dispatch = useDispatch();

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    watch: watchEmail,
  } = useForm();

  const {
    handleSubmit: handleSubmitCode,
    control,
    watch: watchCode,
    setValue: setCodeValue,
  } = useForm({
    defaultValues: {
      code: '',
    },
  });

  const allWatchEmailFields = watchEmail();
  const allWatchCodeFields = watchCode();

  // Redirect if already authenticated
  useEffect(() => {
    const checkSessionWithTimeout = async (timeout = 5000) => {
      return Promise.race([
        Auth.currentSession(),
        new Promise<never>((_, reject) =>
          setTimeout(
            () => reject(new Error('Session request timed out')),
            timeout,
          ),
        ),
      ]);
    };

    const checkAuthAndRedirect = async () => {
      try {
        const session = await checkSessionWithTimeout();
        if (session.isValid()) {
          // If authenticated, redirect to /my-trusty without clearing anything
          router.push('/my-trusty');
          return; // Prevent further actions if user is authenticated
        }
      } catch (err) {
        // Not logged in or session invalid, so clear session data
        // console.log('No active session, clearing data for login');
        // Clear local storage, session storage, cookies, and reset state if not authenticated
        localStorage.clear();
        sessionStorage.clear();
        clearCookies();
        dispatch(userReset());
        dispatch(intakeReset());
        dispatch(homeownerIntakeReset());
        dispatch(buyerIntakeReset());
      }
    };

    checkAuthAndRedirect();

    // OAuth event listener
    window.addEventListener('oauth', handleOauth);

    return () => {
      window.removeEventListener('oauth', handleOauth);
    };
  }, []);

  const handleGetProfile = (data: any) => {
    setCookie(null, 'trustyRole', data.getUser.userType, { path: '/' });
    dispatch(userUpdate(data.getUser));
    progress.finish();
    LogRocket.identify(data?.getUser?.email);

    if (isModal && closeModal) {
      closeModal();
    } else {
      if (router?.query?.redirect) {
        router.push(router?.query?.redirect as string);
      } else {
        router.push('/my-trusty/');
      }
    }
    setLoading(false);
  };

  useQuery({
    query: GET_USER,
    onResult: handleGetProfile,
    onFail: (err) => Sentry.captureException(err),
    immediate,
  });

  const clearCodeInput = () => {
    if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();
    codeRef.current.state.input.fill('');
  };

  const handleResendCode = async () => {
    if (emailSent) {
      setCodeValue('code', '');
      clearCodeInput();
    }
    setError(null);
    setResendCodeLoading(true);
    try {
      cognitoUser.current = await Auth.signIn(
        allWatchEmailFields.email.toLowerCase(),
      );
      setEmailSent(true);
    } catch (err) {
      setError('We couldn’t find a Trusty account with that email');
    } finally {
      setResendCodeLoading(false);
    }
  };

  const handleOauth = () => {
    setTimeout(() => {
      router.push('/my-trusty');
    }, 1000);
  };

  const onSubmitUser = async () => {
    setError(null);
    setLoading(true);
    if (!allWatchEmailFields.email?.length) {
      setError('Email address required');
      setLoading(false);
    } else if (!emailSent && allWatchEmailFields.email?.length) {
      await handleResendCode();
      setLoading(false);
    } else if (emailSent && allWatchCodeFields.code?.length < 6) {
      setLoading(false);
      setError('Please enter valid verification code');
    } else {
      try {
        await Auth.sendCustomChallengeAnswer(
          cognitoUser.current,
          allWatchCodeFields.code,
        );
        await Auth.currentSession();
        setIsVerified(true);
        setImmediate(true);
        progress.start();
      } catch (err) {
        setError('Please enter valid verification code');
        setLoading(false);
        setIsVerified(false);
      }
    }
  };

  return (
    <div>
      <Head path="/login" title="Login" />

      <main>
        <TemplateContainer isModal={isModal}>
          <Styled.Container isModal={isModal} itemsCenter>
            <FlexBox itemsCenter>
              <Styled.LogoContainer>
                <Img
                  src="images/logo-only.png"
                  alt="trusty-logo"
                  width={32}
                  height={32}
                />
              </Styled.LogoContainer>
              <Heading variant="h3">
                {emailSent ? 'Enter the verification code' : 'Log in to Trusty'}
              </Heading>
            </FlexBox>

            <Styled.RightColumn>
              <Styled.InputContainer>
                {!emailSent && (
                  <form onSubmit={handleSubmitEmail(onSubmitUser)}>
                    <Styled.InputWrapper>
                      <Input
                        {...registerEmail('email')}
                        placeholder="Email"
                        $valid={!error}
                        variant="md"
                        required
                        id="emailInput"
                      />
                    </Styled.InputWrapper>
                    <Text color={theme.colors.ice[250]} textAlign="center">
                      We’ll email you a secure code to confirm it’s you.
                    </Text>
                    <FlexBox justifyCenter itemsCenter marginTop={40}>
                      <Button fontSize={18} width="200px" loading={loading}>
                        Continue
                      </Button>
                    </FlexBox>
                  </form>
                )}
                {emailSent && (
                  <form onSubmit={handleSubmitCode(onSubmitUser)}>
                    <Text
                      color={theme.colors.ice[250]}
                      textAlign="center"
                      padding={{ top: 0, right: 10, bottom: 0, left: 10 }}
                    >
                      Check your email for an authentication code from Trusty.
                    </Text>
                    <FlexBox itemsCenter marginTop={20} marginBottom={20}>
                      <Controller
                        name="code"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <CodeInput
                            ref={codeRef}
                            code={value}
                            setCode={onChange}
                          />
                        )}
                      />
                    </FlexBox>

                    <Text
                      onClick={handleResendCode}
                      style={{ cursor: 'pointer' }}
                      color={theme.colors.secondary}
                      bold
                      textAlign="center"
                    >
                      {resendCodeLoading ? 'Resending code...' : 'Resend code'}
                    </Text>
                    <FlexBox
                      keepStyleOnMobile
                      marginBottom={30}
                      marginTop={20}
                      itemsCenter
                      row
                      justifyCenter={!emailSent}
                      justifyBetween={emailSent}
                      gap={20}
                      padding={{ top: 0, right: 10, bottom: 0, left: 10 }}
                    >
                      <Button
                        type="button"
                        onClick={() => {
                          setError('');
                          setEmailSent(false);
                          clearCodeInput();
                        }}
                        variant="tertiary"
                      >
                        Back
                      </Button>

                      <Button
                        type="submit"
                        fontSize={16}
                        width="200px"
                        loading={loading}
                        disabled={isVerified || loading}
                      >
                        Continue
                      </Button>
                    </FlexBox>
                  </form>
                )}

                {error && (
                  <Styled.LoginErrorContainer>
                    <p>{error}</p>
                  </Styled.LoginErrorContainer>
                )}
              </Styled.InputContainer>
            </Styled.RightColumn>
          </Styled.Container>
        </TemplateContainer>
      </main>
    </div>
  );
};

export default LogIn;
